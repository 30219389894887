import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function ReviewServiceAddress(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { leadAddress1, leadAddress2, leadCity, leadState, leadZip } = formValues;
  return (
    <Grid item xs={12} sm={6}>
    <Typography variant="h6" gutterBottom className={classes.title}>
      Service Address
    </Typography> 
    <Typography gutterBottom>{`${leadAddress1}`}</Typography>
    { leadAddress2 ? <Typography gutterBottom>{`${leadAddress2}`}</Typography> : null }
    <Typography gutterBottom>{`${leadCity}`}, {`${leadState}`}</Typography>
    <Typography gutterBottom>{`${leadZip}`}</Typography>
    </Grid>
  );
}

export default ReviewServiceAddress;
