const inquiryFormModel = {
  formId: 'inquiryForm',
  formField: {
    leadName: {
      name: 'leadName',
      label: 'Full Name *',
      requiredErrorMsg: 'Full Name is required'
    },
    leadCompany: {
      name: 'leadCompany',
      label: 'Company Name',
      requiredErrorMsg: 'Company Name is required'
    },
    leadEmail: {
      name: 'leadEmail',
      label: 'E-mail Address *',
      requiredErrorMsg: 'E-Mail Address is required'
    },
    leadPhone: {
      name: 'leadPhone',
      label: 'Phone Number *',
      requiredErrorMsg: 'Phone Number is required'
    },
    leadAddress1: {
      name: 'leadAddress1',
      label: 'Address Line 1 *',
      requiredErrorMsg: 'Address Line 1 is required'
    },
    leadAddress2: {
      name: 'leadAddress2',
      label: 'Address Line 2',
      requiredErrorMsg: 'Address Line 2 is required'
    },
    leadCity: {
      name: 'leadCity',
      label: 'City *',
      requiredErrorMsg: 'City is required'
    },
    leadState: {
      name: 'leadState',
      label: 'State * ',
      requiredErrorMsg: 'City is required'
    },
    leadZip: {
      name: 'leadZip',
      label: 'Zipcode *',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
    },
  }
};

export default inquiryFormModel;