import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputField from "../FormFields/InputField";

export default function ServiceAddress(props) {
    const {
      formField: {
        leadAddress1,
        leadAddress2,
        leadCity,
        leadState,
        leadZip
      }
    } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Service Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={leadAddress1.name} label={leadAddress1.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
         <InputField name={leadAddress2.name} label={leadAddress2.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={leadCity.name} label={leadCity.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={leadState.name} label={leadState.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={leadZip.name} label={leadZip.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </React.Fragment>
  );
}
