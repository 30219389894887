import inquiryFormModel from './inquiryFormModel';
const {
  formField: {
    leadName,
    leadCompany,
    leadEmail,
    leadPhone,
    leadAddress1,
    leadCity,
    leadState,
    leadZip
  }
} = inquiryFormModel;

const formInitialValues = {
  [leadName.name]: '',
  [leadCompany.name]: '',
  [leadEmail.name]: '',
  [leadPhone.name]: '',
  [leadAddress1.name]: '',
  [leadCity.name]: '',
  [leadState.name]: '',
  [leadZip.name]: ''
};

export default formInitialValues;