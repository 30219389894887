import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputField from "../FormFields/InputField";

export default function ContactDetails(props) {
  const {
    formField: {
      leadName,
      leadCompany,
      leadEmail,
      leadPhone
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Contact Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={leadName.name} label={leadName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={leadCompany.name} label={leadCompany.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={leadEmail.name} label={leadEmail.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={leadPhone.name} label={leadPhone.label} fullWidth />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}
