import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import useStyles from '../..//ServiceInquiry/styles.js';

function ConfirmSuccess() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Thank you for your form submission.
      </Typography>
      <Typography variant="subtitle1">
      Your information has been forwarded to our sales department.
      </Typography>
     <p></p>
     <Box textAlign='center'>
      <Button 
        variant="contained"
        color="primary"
        align="center"
        className={classes.Button}
        href="https://ntad.net"
      >
      Go To: Network Tool and Die Homepage
      </Button>
    </Box>
      
    </React.Fragment>
  );
}

export default ConfirmSuccess;
