import * as Yup from 'yup';
import inquiryFormModel from './inquiryFormModel';
const {
  formField: {
    leadName,
    leadEmail,
    leadPhone,
    leadAddress1,
    leadCity,
    leadState,
    leadZip
  }
} = inquiryFormModel;

const validationSchema = [
  Yup.object().shape({
    [leadName.name]: Yup.string()
      .required(`${leadName.requiredErrorMsg}`),
    [leadEmail.name]: Yup.string()
      .required(`${leadEmail.requiredErrorMsg}`),
    [leadPhone.name]: Yup.string()
      .required(`${leadPhone.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [leadAddress1.name]: Yup.string()
      .required(`${leadAddress1.requiredErrorMsg}`),
    [leadCity.name]: Yup.string()
      .nullable()
      .required(`${leadCity.requiredErrorMsg}`),
    [leadState.name]: Yup.string()
      .nullable()
      .required(`${leadState.requiredErrorMsg}`),
    [leadZip.name]: Yup.string()
        .required(`${leadZip.requiredErrorMsg}`)
        .test(
        'len',
        `${leadZip.invalidErrorMsg}`,
        val => val && val.length === 5
      ),
  }),
];

export default validationSchema;