import React from 'react';
import MaterialLayout from './components/Layout/MaterialLayout';
import ServiceInquiry from './components/ServiceInquiry';
// import Pricing from './components/Pricing'

function App() {
  return (
    <div>
      <MaterialLayout>
        <ServiceInquiry />
      </MaterialLayout>
    </div>
  );
}

export default App;
